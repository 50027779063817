import React, { useEffect, useState } from "react";
import "./styles.css";
import { withStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Card from "./card";
import { Button, createMuiTheme, ThemeProvider } from "@material-ui/core";
import actions from "./data";
import Dialog from "./dialog";
import Processing from "./processing";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import CircularProgress from "@material-ui/core/CircularProgress";
//import Masonry from "react-masonry-css";
import Masonry from "react-masonry-component";

const masonryOptions = {
  transitionDuration: 1
};

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ff6a97",
      main: "#d03469",
      dark: "#9a003f",
      contrastText: "#fff"
    },
    secondary: {
      light: "#c65bcb",
      main: "#93289a",
      dark: "#62006b",
      contrastText: "#fff"
    }
  },
  status: {
    danger: "orange"
  }
});

const CircularIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={20} color="secondary" />
    </div>
  );
};

const getHeight = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      if (document.querySelector("form") && document.querySelector("h1")) {
        const height = document.documentElement.clientHeight - 300;
        console.log(height);
        return resolve(height > 300 ? height : 300);
      } else {
        return resolve(300);
      }
    }, 100);
  });
};

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: 8,
      maxWidth: 400
    },
    "& fieldset": {
      backgroundColor: "white"
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "white"
    },
    "& input": {
      //border: "none",

      zIndex: 999
    }
  }
}));

const ColorButton = withStyles(theme => ({
  root: {
    color: "#FFFFFF",
    backgroundColor: "#ff6a97"
  }
}))(Button);

const App = () => {
  const [records, setRecords] = useState([]);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [height, setHeight] = useState(getHeight());
  const [from, setFrom] = useState("");
  const [data, setData] = useState("");
  const [gettingData, setGettingData] = useState(true);
  const resizeListener = () => {
    // change width from the state object
    getHeight().then(_height => setHeight(_height));
  };
  useEffect(() => {
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = e => {
    console.log("running change");
    if (e.target.name === "from") {
      setFrom(e.target.value);
    }
    if (e.target.name === "data") {
      setData(e.target.value);
    }
  };
  const classes = useStyles();
  const getAndSet = () => {
    setGettingData(true);
    actions.getLatest().then(data => {
      setRecords(data);
      setGettingData(false);
    });
  };
  const onsubmit = event => {
    event.preventDefault();
    const data = {
      user: event.target.from.value,
      Notes: event.target.data.value
    };
    if (data.user.length >= 3 && data.Notes.length >= 3) {
      setProcessing(true);
      actions.create(data.user, data.Notes).then(() => {
        setTimeout(() => {
          getAndSet();
          setProcessing(false);
          setFrom("");
          setData("");
        }, 100);
      });
    } else {
      handleClickOpen();
    }
  };

  useEffect(() => {
    getAndSet();
    setTimeout(() => {
      resizeListener();
    }, 100);
    setInterval(() => {
      getAndSet();
    }, 20000);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="top">
          <h1>
            Don't do <i>it</i>&nbsp;today!
          </h1>
          <form
            action="#"
            onSubmit={onsubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <FormControl>
              <TextField
                name="from"
                fullWidth
                value={from}
                id="outlined-basic"
                label="I am..."
                margin="normal"
                variant="filled"
                onChange={onChange}
              />
              <br />
              <TextField
                name="data"
                fullWidth
                id="outlined-basic"
                label="I won't do today..."
                margin="normal"
                variant="filled"
                value={data}
                required
                onChange={onChange}
              />
              <br />
              <ColorButton
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Submit
              </ColorButton>
            </FormControl>
          </form>
        </div>
        <div class="lists" style={{ height: height + "px" }}>
          {gettingData && <CircularIndeterminate />}
          <div className="shadow-top">&nbsp;</div>
          {records.length > 0 && (
            <div className="list">
              <SimpleBar
                style={{ maxHeight: height + "px", padding: "50px 1em" }}
                autoHide={true}
              >
                <Masonry
                  className={"my-gallery-class"} // default ''
                  options={masonryOptions} // default {}
                  disableImagesLoaded={false} // default false
                  updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                >
                  {/*<Masonry
                  breakpointCols={3}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >*/}
                  {/* array of JSX items */}
                  {records.map(record => (
                    <Card {...record} />
                  ))}
                </Masonry>
              </SimpleBar>
            </div>
          )}
          <div className="shadow-bottom">&nbsp;</div>
        </div>
        <Dialog open={open} handleClose={handleClose} />
        <Processing open={processing} />
      </div>
    </ThemeProvider>
  );
};

export default App;
