import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    /*minWidth: 240,
    maxWidth: 320,*/
    //minHeight: 250
    /*width: "100%"*/
    margin: "1em"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

const CustomCard = ({ user, Notes, created }) => {
  const classes = useStyles();
  return (
    <div className="card-wrapper">
      <Card className={classes.root} raised>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            From <strong>{user}</strong>
          </Typography>
          <Typography variant="body2" component="p">
            <Box fontStyle="italic" m={0} p={0}>
              I won't do today....
            </Box>
          </Typography>
          <Typography variant="h5" component="h2">
            {Notes}
          </Typography>
          <Typography
            variant="caption"
            component="h2"
            style={{
              textAlign: "right",
              paddingTop: "1em",
              fontWeight: 500,
              color: "#888"
            }}
          >
            {format(new Date(created), "yyyy/dd/MM HH:mm:ss")}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default CustomCard;
