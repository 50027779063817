var Airtable = require("airtable");
Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: "keyWZrQiyMfcHV4uC"
});
var base = Airtable.base("appFYkA0aBuGpPqxA");

const actions = {
  create(from, data) {
    return new Promise((resolve, reject) => {
      base("Table 1").create(
        [
          {
            fields: {
              user: from,
              Notes: data
            }
          }
        ],
        function(err, records) {
          if (err) {
            console.error(err);
            reject(err);
            return;
          }
          resolve();
          records.forEach(function(record) {
            console.log(record.getId());
          });
        }
      );
    });
  },
  getLatest() {
    return new Promise((resolve, reject) => {
      const records = [];
      base("Table 1")
        .select({
          // Selecting the first 3 records in Grid view:
          maxRecords: 10,
          view: "Grid view",
          sort: [{ field: "created", direction: "desc" }]
        })
        .eachPage(
          function page(records_, fetchNextPage) {
            // This function (`page`) will get called for each page of records.

            /*records.forEach(function(record) {
            console.log("Retrieved", record.get("user"));
          });*/
            const processedRecords = records_
              .map(e => e._rawJson)
              .map(e => e.fields);
            console.log("records: ", processedRecords);
            processedRecords.forEach(record => records.push(record));
            // To fetch the next page of records, call `fetchNextPage`.
            // If there are more records, `page` will get called again.
            // If there are no more records, `done` will get called.
            fetchNextPage();
          },
          function done(err) {
            if (err) {
              console.error(err);
              reject(err);
              return;
            }
            console.log(records);
            resolve(records);
          }
        );
    });
  }
};
export default actions;
